import λ from '~/utils/invoker';
import { getId } from '#/object-id';
import FormMixin from '~/mixins/form';
import { routeNames } from '~/router';
import PermissionMixin from '~/mixins/permission';
import CreditForItemMixin from '~/mixins/creditForItem';
import GoBackWrapper from '~/components/go-back-wrapper.vue';
import AgencyFields from '~/agencies/agency-fields.vue';
import { ValidationObserver } from 'vee-validate';
import { mdiContentSaveOutline, mdiViewList } from '@mdi/js';
const agenciesλ = λ.namespaced('agencies');
export default {
    name: 'agency-form',
    mixins: [FormMixin('agencies'), CreditForItemMixin('agency'), PermissionMixin],
    components: {
        ValidationObserver,
        GoBackWrapper,
        AgencyFields,
    },
    props: {
        id: {
            type: String,
        },
    },
    data() {
        return {
            mdiViewList,
            mdiContentSaveOutline,
        };
    },
    async created() {
        await this.prepareForm(this.id);
    },
    computed: {
        item: agenciesλ.state('editing'),
        canList: λ.self('canListAgencies'),
        hasId() {
            return Boolean(getId(this.item));
        },
        canSave() {
            return this.canUpdateAgencies || this.canUpdateOwnAgency(this.id);
        },
    },
    methods: {
        goToParticipationsList() {
            this.$router.push({
                name: routeNames.participations.list,
                query: { agency: getId(this.item) },
                params: { goBackTo: { name: routeNames.agencies.edit, params: { id: getId(this.item) } } },
            });
        },
    },
};
